import { all, call, select, takeLatest } from '@redux-saga/core/effects';
import { AxiosResponse } from 'axios';
import { addBusinessDays, isThursday } from 'date-fns';
import { toast } from 'react-toastify';
import { debounce, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';

import lgmApi from '../../../services/lgmService';
import LgmEstimatedDto from '../../../types/Lgm/LgmEstimatedDto';
import {
  LgmEstimatedMonthValues,
  LgmEstimatedPayload
} from '../../../types/Lgm/LgmEstimatedPayload';
import { LgmRow } from '../../../types/Lgm/LgmRow';
import { LgmPremiumDto } from '../../../types/dto/LgmPremiumDto';
import { formatDateISO } from '../../../utils/dateFormatter';
import { lgmLoaderActions } from '../loaders/actions';
import { lgmSettingsActions } from '../settings/actions';
import { FeedType } from '../settings/reducer';
import {
  getActualPrices,
  getLgmCalendarDate,
  getLgmDeductible,
  getLgmFeedType
} from '../settings/selectors';
import { lgmWhatIfActions } from '../whatif/actions';
import { lgmActions } from './actions';
import { lgmDefaultPremium } from './reducers';
import { getLgmRowData } from './selectors';

function* getLgmPayload() {
  try {
    const calendarDate: Date = yield select(getLgmCalendarDate);
    const deductibleLevel: number = yield select(getLgmDeductible);
    const monthValues: LgmRow[] = yield select(getLgmRowData);
    const actualPrices: boolean = yield select(getActualPrices);

    const payload: LgmEstimatedPayload = {
      actualPrices: actualPrices,
      salesDate: formatDateISO(calendarDate),
      deductibleLevel,
      monthValues: monthValues.map(
        ({ date, quantity }: LgmRow): LgmEstimatedMonthValues => ({
          date: formatDateISO(date),
          coveredMilkCwt: quantity.milk,
          coveredCorn: quantity.corn,
          coveredSoybeanMeal: quantity.sbm
        })
      )
    };

    return payload;
  } catch (e) {
    console.log(e);
  }
}

function* getLgmEstimated() {
  yield put(lgmLoaderActions.setLgmEstimatedLoader(true));
  yield put(lgmLoaderActions.setLgmPremiumLoader(true));

  const payloadGlobal: LgmEstimatedPayload = yield call(getLgmPayload);
  let lgmRows: LgmRow[] = [];

  try {
    const lgmEstimatedResponse: AxiosResponse<LgmEstimatedDto> = yield call(
      lgmApi.getLgmEstimated,
      payloadGlobal
    );
    lgmRows = lgmEstimatedResponse.data.monthValues.map((e, index) => ({
      id: index,
      date: new Date(e.date),
      prices: {
        milk: e.milkExpectedPrice,
        corn: e.cornExpectedPrice,
        sbm: e.soybeanMealExpectedPrice
      },
      quantity: {
        milk: e.coveredMilkCwt,
        corn: e.coveredCorn,
        sbm: e.coveredSoybeanMeal
      }
    }));

    yield put(lgmActions.saveLgmEstimated(lgmEstimatedResponse.data));
  } catch (e) {
    toast.error('Cannot retrieve LGM Estimates.');
    console.error(e);
  } finally {
    yield put(lgmLoaderActions.setLgmEstimatedLoader(false));
  }

  try {
    const lgmPremiumResponse: AxiosResponse<LgmPremiumDto> = yield call(
      lgmApi.getLgmPremium,
      payloadGlobal
    );
    yield put(lgmActions.saveLgmPremium(lgmPremiumResponse.data));
  } catch (e) {
    toast.error('Cannot retrieve LGM Premiums.');
    yield put(
      lgmActions.saveLgmPremium({
        custom: lgmDefaultPremium,
        default: lgmDefaultPremium,
        lowest: lgmDefaultPremium,
        highest: lgmDefaultPremium
      })
    );
    console.error(e);
  } finally {
    yield put(lgmLoaderActions.setLgmPremiumLoader(false));
  }

  yield put(lgmWhatIfActions.getLgmWhatIfActual(payloadGlobal));

  yield put(lgmActions.addRows(lgmRows));
}

function* getLgmPremium() {
  try {
    const payload: LgmEstimatedPayload = yield call(getLgmPayload);

    const lgmPremiumResponse: AxiosResponse<LgmPremiumDto> = yield call(
      lgmApi.getLgmPremium,
      payload
    );
    yield put(lgmActions.saveLgmPremium(lgmPremiumResponse.data));
    toast.success('Gross Margin Recalculated');
  } catch (e) {
    console.log(e);
  }
}

function* updateLgmRow(action: ActionType<typeof lgmActions.updateRow>) {
  try {
    let { milk, corn, sbm } = action.payload.values;

    if (milk > 240000) {
      milk = 240000;
      toast.error('Maximum value of Milk Quantity is 240,000 hundredweight', {
        position: 'top-center'
      });
    }

    const monthValues: LgmRow[] = yield select(getLgmRowData);
    const isOnlyMilkChanged =
      monthValues[action.payload.id].quantity.corn === corn &&
      monthValues[action.payload.id].quantity.sbm === sbm;

    if (!isOnlyMilkChanged) {
      yield put(lgmSettingsActions.setFeedType(FeedType.CUSTOM));
    }
    const feed: FeedType = isOnlyMilkChanged ? yield select(getLgmFeedType) : FeedType.CUSTOM;

    if (milk * 0.00364 > corn || feed === FeedType.LOWEST) {
      corn = Math.round(milk * 0.00364 * 100) / 100;
    }
    if (milk * 0.000805 > sbm || feed === FeedType.LOWEST) {
      sbm = Math.round(milk * 0.000805 * 100) / 100;
    }

    if (corn > milk * 0.0381 || feed === FeedType.HIGHEST) {
      corn = Math.round(milk * 0.0381 * 100) / 100;
    }
    if (sbm > milk * 0.013 || feed === FeedType.HIGHEST) {
      sbm = Math.round(milk * 0.013 * 100) / 100;
    }

    if (feed === FeedType.DEFAULT) {
      corn = Math.round(milk * 0.014 * 100) / 100;
      sbm = Math.round(milk * 0.002 * 100) / 100;
    }

    yield put(lgmActions.changeRow({ milk, corn, sbm }, action.payload.id));

    yield call(getLgmEstimated);
  } catch (e) {
    console.error(e);
  }
}

function* initialization() {
  try {
    const date = addBusinessDays(addBusinessDays(new Date(), 0), -1);
    yield put(lgmSettingsActions.setCalendarDate(date));
    yield put(lgmSettingsActions.setActualPrices(isThursday(date)));
  } catch (e) {
    console.error(e);
  }
}

export function* lgmSagaWatcher() {
  yield all([
    takeLatest(getType(lgmActions.getLgmEstimated), getLgmEstimated),
    takeLatest(getType(lgmActions.updateRow), updateLgmRow),
    takeLatest(getType(lgmActions.getLgmPremium), getLgmPremium),
    takeLatest(getType(lgmActions.initialization), initialization),
    debounce(800, getType(lgmSettingsActions.setDeductible), getLgmEstimated)
  ]);
}
