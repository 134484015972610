import { isAfter } from 'date-fns';

import { Nullable } from '../@types/typeHelpers';

export const makeUniqueArrayObjects = <T extends { [key: string | number]: string | number }>(
  array: T[],
  key: string
) => {
  return array.filter(
    (item, index, self) => self.findIndex(t => t[key.toString()] === item[key.toString()]) === index
  );
};

export const sortArrayOfObjects = <T extends { [key: string]: string | number }>(
  array: T[],
  key: string
) => {
  return array.sort((a, b) => a[key].toString().localeCompare(b[key].toString()));
};

const sortByEndMonth = <T extends Nullable<'endYear' | 'endMonth'>>(values: T[]): T[] => {
  return [...values].sort((a, b) => {
    if (a.endYear && b.endYear && a.endMonth && b.endMonth) {
      if (a.endYear < b.endYear) {
        return -1;
      }
      if (a.endYear === b.endYear && a.endMonth < b.endMonth) {
        return -1;
      }
    }
    return 1;
  });
};

export const getLatestOrCurrent = <T extends Nullable<'endYear' | 'endMonth'>>(
  values: T[]
): Date => {
  const sortedArray = sortByEndMonth(values);
  if (sortedArray.length !== 0) {
    const currentDate = new Date();
    const lastObjectInArray = sortedArray[sortedArray.length - 1];
    const lastObjectYear = lastObjectInArray.endYear;
    const lastObjectMonth = lastObjectInArray.endMonth;
    const lastObjectDate = new Date(
      lastObjectYear ?? currentDate.getFullYear(),
      lastObjectMonth ?? currentDate.getMonth(),
      0
    );
    if (isAfter(lastObjectDate, currentDate)) {
      return new Date();
    }
    return lastObjectDate;
  }
  return new Date();
};

export const getFirstOrCurrent = <T extends Nullable<'endYear' | 'endMonth'>>(
  values: T[]
): Date => {
  const sortedArray = sortByEndMonth(values);

  if (sortedArray.length !== 0) {
    const currentDate = new Date();
    const firstObjectInArray = sortedArray[0];
    const firstObjectYear = firstObjectInArray.endYear;
    const firstObjectMonth = firstObjectInArray.endMonth
      ? firstObjectInArray.endMonth - 1
      : currentDate.getMonth();
    const firstObjectDate = new Date(
      firstObjectYear ?? currentDate.getFullYear(),
      firstObjectMonth ?? currentDate.getMonth(),
      1
    );
    if (isAfter(firstObjectDate, currentDate)) {
      return new Date();
    }
    return firstObjectDate;
  }
  return new Date();
};

export const totalByKey = <T extends string>(
  items: (Nullable<T, number> | undefined | null)[],
  key: T
): number =>
  (items ?? []).reduce((acc, cur) => {
    if (cur && cur[key]) {
      const value = cur[key];
      return acc + (typeof value === 'number' ? value : 0);
    }
    return acc;
  }, 0);
