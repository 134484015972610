import { getQuarter as dateFnsGetQuarter, subMonths } from 'date-fns';
import { createSelector } from 'reselect';

import { Unit } from '../../containers/PremiumsContainer/PremiumsContainer';
import AllQuarters, {
  AllQuarterCalculatedValue,
  AllQuartersKeys,
  AllQuartersMeasures
} from '../../types/AllQuarters';
import { Units } from '../../types/Units';
import {
  formatDateMonth,
  formatDateYearMonth,
  formatDateYearMonthDay
} from '../../utils/dateFormatter';
import { Language } from '../../utils/language';
import { formatNumber } from '../../utils/numberFormatter';
import { getStatesList } from '../areas/selectors';
import { getPremiumPropsFromProps, getUnitFromProps } from '../fromPropsSelector';
import { RootState } from '../store';

export const getEndorsementSettings = (state: RootState) => state.dairyRevenueProtection.settings;

export const getState = (state: RootState) => state.dairyRevenueProtection.settings.state;
export const getQuarter = (state: RootState) => state.dairyRevenueProtection.settings.quarter;
export const getCanSell = (state: RootState) => state.dairyRevenueProtection.settings.canSell;
export const getCanSellNote = (state: RootState) =>
  state.dairyRevenueProtection.settings.canSellNote;
export const getDeclaredMilkProduction = (state: RootState) =>
  state.dairyRevenueProtection.settings.declaredMilkProduction;
export const getSalesEffectiveDate = (state: RootState) =>
  state.dairyRevenueProtection.settings.salesEffectiveDate;
export const getCalendarDate = (state: RootState) =>
  state.dairyRevenueProtection.settings.calendarDate;
export const getCoverageLevel = (state: RootState) =>
  state.dairyRevenueProtection.settings.coverageLevel;
export const getDeclaredButterfatTest = (state: RootState) =>
  state.dairyRevenueProtection.settings.declaredButterfatTest;
export const getProtectionFactor = (state: RootState) =>
  state.dairyRevenueProtection.settings.protectionFactor;
export const getDeclaredProteinTest = (state: RootState) =>
  state.dairyRevenueProtection.settings.declaredProteinTest;
export const getDeclaredClassPriceWeightingFactor = (state: RootState) =>
  state.dairyRevenueProtection.settings.declaredClassPriceWeightingFactor;
export const getDeclaredComponentPriceWeightingFactor = (state: RootState) =>
  state.dairyRevenueProtection.settings.declaredComponentPriceWeightingFactor;
export const getQuarterList = (state: RootState) =>
  state.dairyRevenueProtection.settings.quarterList;
export const getShowBasis = (state: RootState) => state.dairyRevenueProtection.settings.showBasis;
export const getShowIndemnity = (state: RootState) =>
  state.dairyRevenueProtection.settings.showIndemnity;
export const getShowSubsidy = (state: RootState) =>
  state.dairyRevenueProtection.settings.showSubsidy;
export const getShowAllQuarters = (state: RootState) =>
  state.dairyRevenueProtection.settings.showAllQuarters;
export const getShowProtectionFactorAnalysis = (state: RootState) =>
  state.dairyRevenueProtection.settings.showProtectionFactorAnalysis;
export const getReinsuranceYear = (state: RootState) =>
  state.dairyRevenueProtection.settings.reinsuranceYear;

export const getPriceReport = (state: RootState) => state.dairyRevenueProtection.reports.data;
export const getPriceReportFistDate = (state: RootState) =>
  state.dairyRevenueProtection.reports.fistDate;

export const getButter = (state: RootState) => state.dairyRevenueProtection.whatIfInputs.butter;
export const getCheese = (state: RootState) => state.dairyRevenueProtection.whatIfInputs.cheese;
export const getDryWhey = (state: RootState) => state.dairyRevenueProtection.whatIfInputs.dryWhey;
export const getNoFatDryMilk = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.noFatDryMilk;
export const getMilkProductionPerCow = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.milkProductionPerCow;
export const getActualMilkProduction = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.actualMilkProduction;
export const getActualButterfatTest = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.actualButterfatTest;
export const getActualProteinTest = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.actualProteinTest;

export const getAllRawWhatIfDairyValues = (state: RootState) =>
  state.dairyRevenueProtection.whatIfDairyValues;

export const getWhatIfRaw = (state: RootState) => state.dairyRevenueProtection.whatIfRaw;
export const getIndemnity = (state: RootState) => state.dairyRevenueProtection.indemnity;
export const getAllWhatIfInputs = (state: RootState) => state.dairyRevenueProtection.whatIfInputs;
export const getAllWhatIfExpectedActual = (state: RootState) =>
  state.dairyRevenueProtection.whatIfExpectedActual;
export const getAllWhatIfComponents = (state: RootState) =>
  state.dairyRevenueProtection.whatIfDairyComponents;

export const getAllPriceComponents = (state: RootState) =>
  state.dairyRevenueProtection.priceComponents;
export const getProducerPremiumCwtFromPriceComponents = (state: RootState) =>
  state.dairyRevenueProtection.priceComponents.producerPremiumAmountCwt;
export const getErrorMessage = (state: RootState) => state.loaders.showSalesError;

export const getHistoricalTimeSpan = (state: RootState) =>
  state.dairyRevenueProtection.historical.timeSpan;
export const getHistoricalRecords = (state: RootState) =>
  state.dairyRevenueProtection.historical.records;
export const getIsClassChartSelected = (state: RootState) =>
  state.dairyRevenueProtection.historical.isClassChart;
export const getHistoricalAnalysisSummary = (state: RootState) =>
  state.dairyRevenueProtection.historical.summary;
export const getHistoricalAnalysisHorizon = (state: RootState) =>
  state.dairyRevenueProtection.historical.records[0]
    ? state.dairyRevenueProtection.historical.records[0].horizon
    : 0;
export const getHistoricalAnalysisDataState = (state: RootState) =>
  state.dairyRevenueProtection.historical.isDataAvailable;

export const getAllQuartersRaw = (state: RootState) => state.dairyRevenueProtection.allQuarters;
export const getShare = (state: RootState) => state.dairyRevenueProtection.settings.share;

export const getIsSalesDateSuspendedForComponent = (state: RootState) =>
  state.dairyRevenueProtection.priceComponents.premiumBeforeSubsidy.component === 0;
export const getIsSalesDateSuspendedForClass = (state: RootState) =>
  state.dairyRevenueProtection.priceComponents.premiumBeforeSubsidy.class === 0;

export const getStateName = createSelector(getStatesList, getState, (list, id) => {
  const state = list[list.findIndex(({ stateCode }) => Number(stateCode) === id)];
  if (state !== undefined) {
    return state.stateName;
  }
  return '';
});

export const getQuarterName = createSelector(getQuarterList, getQuarter, (list, id) => {
  if (list.length === 0) {
    return 'none';
  }
  const quarter = list[list.findIndex(({ practiceCode }) => practiceCode === id)];
  if (!quarter) {
    return 'none';
  }
  return quarter.quarter;
});

export const getSelectedQuarter = createSelector(getQuarterList, getQuarter, (list, id) => {
  if (list.length === 0) {
    return undefined;
  }
  return list.find(({ practiceCode }) => practiceCode === id);
});

export const getQuarterSelectList = createSelector(getQuarterList, list =>
  list.map(({ quarter, practiceCode }) => ({ name: quarter, id: practiceCode }))
);

const whatIfDairyValuesMapper = ({ date, value }: { date: Date; value: number }) => ({
  date: formatDateYearMonth(date),
  value
});
export const getAllWhatIfDairyValues = createSelector(
  getAllRawWhatIfDairyValues,
  ({ butter, cheese, dryWhey, noFatDryMilk, classIIIMilk, classIVMilk, milkProductionPerCow }) => ({
    availablePrices: {
      component: butter.some(({ value }) => value !== 0),
      cheese: cheese.some(({ value }) => value !== 0),
      dryWhey: dryWhey.some(({ value }) => value !== 0),
      noFatDryMilk: noFatDryMilk.some(({ value }) => value !== 0),
      class:
        classIIIMilk.some(({ value }) => value !== 0) &&
        classIVMilk.some(({ value }) => value !== 0)
    },
    butter: butter.map(whatIfDairyValuesMapper),
    cheese: cheese.map(whatIfDairyValuesMapper),
    dryWhey: dryWhey.map(whatIfDairyValuesMapper),
    noFatDryMilk: noFatDryMilk.map(whatIfDairyValuesMapper),

    milkProductionPerCow: {
      value: milkProductionPerCow.value,
      date: `${formatDateMonth(
        subMonths(new Date(milkProductionPerCow.date), 2)
      )} - ${formatDateYearMonth(new Date(milkProductionPerCow.date))}`
    },

    classIIIMilk: classIIIMilk.map(whatIfDairyValuesMapper),
    classIVMilk: classIVMilk.map(whatIfDairyValuesMapper)
  })
);

export const getActualClassIIIPrice = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.classIIIMilk;

export const getActualClassIVPrice = (state: RootState) =>
  state.dairyRevenueProtection.whatIfInputs.classIVMilk;

export const getPremiumQuarter = createSelector(
  getAllPriceComponents,
  getAllWhatIfComponents,
  getPremiumPropsFromProps,
  (priceComponents, whatIf, premiumProps) => {
    const table: Array<{ name: string; component: string; class: string }> = [];
    table.push({
      name: 'Expected yield',
      component: `${formatNumber(0)(priceComponents.expectedYield.component)}`,
      class: `${formatNumber(0)(priceComponents.expectedYield.class)}`
    });
    if (premiumProps.unit === Unit.DOLLAR) {
      table.push({
        name: 'Expected Revenue',
        component: `${formatNumber(0)(priceComponents.expectedRevenue.component, Units.DOLLAR)}`,
        class: `${formatNumber(0)(priceComponents.expectedRevenue.class, Units.DOLLAR)}`
      });
      table.push({
        name: 'Revenue Guarantee',
        component: `${formatNumber(0)(priceComponents.revenueGuarantee.component, Units.DOLLAR)}`,
        class: `${formatNumber(0)(priceComponents.revenueGuarantee.class, Units.DOLLAR)}`
      });
    } else {
      table.push({
        name: 'Expected Revenue',
        component: `${formatNumber(2)(whatIf.expectedDRPRevenueCwt.component, Units.DOLLAR)}`,
        class: `${formatNumber(2)(whatIf.expectedDRPRevenueCwt.class, Units.DOLLAR)}`
      });
      table.push({
        name: 'Revenue Guarantee',
        component: `${formatNumber(2)(
          priceComponents.revenueGuaranteeCwt.component,
          Units.DOLLAR
        )}`,
        class: `${formatNumber(2)(priceComponents.revenueGuaranteeCwt.class, Units.DOLLAR)}`
      });
    }

    if (premiumProps.useBasis) {
      if (premiumProps.unit === Unit.DOLLAR) {
        table.push({
          name: 'Expected Basis',
          component: `${Math.floor(priceComponents.basis.component)}`,
          class: `${Math.floor(priceComponents.basis.class)}`
        });
      } else {
        table.push({
          name: 'Expected Basis',
          component: `${priceComponents.basisCwt.component}`,
          class: `${priceComponents.basisCwt.class}`
        });
      }
    }

    if (premiumProps.useProtectionFactorAnalysis) {
      table.push({
        name: 'Effective Covered Milk Production',
        component: `${formatNumber(0)(priceComponents.milkProductionEffective.component)}`,
        class: `${formatNumber(0)(priceComponents.milkProductionEffective.class)}`
      });
    }

    if (premiumProps.useSubsidy) {
      if (premiumProps.unit === Unit.DOLLAR) {
        table.push({
          name: 'Premium Before Subsidy',
          component: `${formatNumber(0)(
            priceComponents.premiumBeforeSubsidy.component,
            Units.DOLLAR
          )}`,
          class: `${formatNumber(0)(priceComponents.premiumBeforeSubsidy.class, Units.DOLLAR)}`
        });
        table.push({
          name: 'Subsidy',
          component: `${formatNumber(0)(priceComponents.subsidy.component, Units.DOLLAR)}`,
          class: `${formatNumber(0)(priceComponents.subsidy.class, Units.DOLLAR)}`
        });
      } else {
        table.push({
          name: 'Premium Before Subsidy',
          component: `${formatNumber(4)(
            priceComponents.premiumBeforeSubsidyCwt.component,
            Units.DOLLAR
          )}`,
          class: `${formatNumber(4)(priceComponents.premiumBeforeSubsidyCwt.class, Units.DOLLAR)}`
        });
        table.push({
          name: 'Subsidy',
          component: `${formatNumber(4)(priceComponents.subsidyCwt.component, Units.DOLLAR)}`,
          class: `${formatNumber(4)(priceComponents.subsidyCwt.class, Units.DOLLAR)}`
        });
      }
    }

    if (premiumProps.unit === Unit.DOLLAR) {
      table.push({
        name: 'Producer Premium Amount',
        component: `${formatNumber(0)(
          priceComponents.producerPremiumAmount.component,
          Units.DOLLAR
        )}`,
        class: `${formatNumber(0)(priceComponents.producerPremiumAmount.class, Units.DOLLAR)}`
      });
      table.push({
        name: 'Expected Revenue Floor',
        component: `${formatNumber(0)(
          premiumProps.useBasis
            ? priceComponents.expectedPriceFloorNet.component
            : priceComponents.expectedPriceFloor.component,
          Units.DOLLAR
        )}`,
        class: `${formatNumber(0)(
          premiumProps.useBasis
            ? priceComponents.expectedPriceFloorNet.class
            : priceComponents.expectedPriceFloor.class,
          Units.DOLLAR
        )}`
      });
    } else {
      table.push({
        name: 'Producer Premium Amount',
        component: `${formatNumber(4)(
          priceComponents.producerPremiumAmountCwt.component,
          Units.DOLLAR
        )}`,
        class: `${formatNumber(4)(priceComponents.producerPremiumAmountCwt.class, Units.DOLLAR)}`
      });
      table.push({
        name: 'Expected Revenue Floor',
        component: `${formatNumber(2)(
          premiumProps.useBasis
            ? priceComponents.expectedPriceFloorNetCwt.component
            : priceComponents.expectedPriceFloorCwt.component,
          Units.DOLLAR
        )}`,
        class: `${formatNumber(2)(
          premiumProps.useBasis
            ? priceComponents.expectedPriceFloorNetCwt.class
            : priceComponents.expectedPriceFloorCwt.class,
          Units.DOLLAR
        )}`
      });
    }

    if (premiumProps.useBasis) {
      if (premiumProps.unit === Unit.DOLLAR) {
        table.push({
          name: 'Cost of Production',
          component: `${
            !priceComponents.costOfProduction.component
              ? ''
              : formatNumber(0)(priceComponents.costOfProduction.component, Units.DOLLAR)
          }`,
          class: `${
            !priceComponents.costOfProduction.class
              ? ''
              : formatNumber(0)(priceComponents.costOfProduction.class, Units.DOLLAR)
          }`
        });
      } else {
        table.push({
          name: 'Cost of Production',
          component: `${
            !priceComponents.costOfProductionCwt.component
              ? ''
              : formatNumber(2)(priceComponents.costOfProductionCwt.component, Units.DOLLAR)
          }`,
          class: `${
            !priceComponents.costOfProductionCwt.class
              ? ''
              : formatNumber(2)(priceComponents.costOfProductionCwt.class, Units.DOLLAR)
          }`
        });
      }
    }

    if (premiumProps.useProbability) {
      table.push({
        name: 'Probability of Positive Indemnity',
        component: `${formatNumber(0)(
          priceComponents.probabilityOfPositiveIndemnity.component,
          Units.PERCENT
        )}`,
        class: `${formatNumber(0)(
          priceComponents.probabilityOfPositiveIndemnity.class,
          Units.PERCENT
        )}`
      });
      table.push({
        name: 'Probability of Positive Net Benefit',
        component: `${formatNumber(0)(
          priceComponents.probabilityOfPositiveNetBenefit.component,
          Units.PERCENT
        )}`,
        class: `${formatNumber(0)(
          priceComponents.probabilityOfPositiveNetBenefit.class,
          Units.PERCENT
        )}`
      });
    }

    return table;
  }
);

export const getWhatIfNetIndemnity = createSelector(
  getAllPriceComponents,
  getIndemnity,
  (priceComponents, indemnity) => ({
    component: indemnity.component.grossIndemnity - priceComponents.producerPremiumAmount.component,
    class: indemnity.class.grossIndemnity - priceComponents.producerPremiumAmount.class,
    componentPerCwt:
      indemnity.component.grossIndemnityCwt - priceComponents.producerPremiumAmountCwt.component,
    classPerCwt: indemnity.class.grossIndemnityCwt - priceComponents.producerPremiumAmountCwt.class
  })
);

export const getHistoricalChartData = createSelector(
  getHistoricalRecords,
  getCoverageLevel,
  getIsClassChartSelected,
  (records, coverageLevel, isClassChartSelected) => {
    if (!isClassChartSelected) {
      return records
        .filter(({ class: classData }) => classData !== null)
        .map(({ reportPeriod, class: classData, collectionDate }) => ({
          year: reportPeriod.getFullYear(),
          quarter: dateFnsGetQuarter(reportPeriod),
          name: `Q${dateFnsGetQuarter(reportPeriod)}-${reportPeriod.getFullYear()}`,
          actual: classData!.actualPrice,
          expected: classData!.expectedPrice,
          difference: classData!.indemnity > 0 ? classData!.indemnity : null,
          indemnity: classData!.indemnity,
          revenue: formatNumber(2)(classData!.expectedPrice * coverageLevel),
          salesDate: formatDateYearMonthDay(collectionDate)
        }));
    } else {
      return records
        .filter(({ component }) => component !== null)
        .map(({ reportPeriod, component, collectionDate }) => ({
          year: reportPeriod.getFullYear(),
          quarter: dateFnsGetQuarter(reportPeriod),
          name: `Q${dateFnsGetQuarter(reportPeriod)}-${reportPeriod.getFullYear()}`,
          actual: component!.actualPrice,
          expected: component!.expectedPrice,
          difference: component!.indemnity > 0 ? component!.indemnity : null,
          indemnity: component!.indemnity,
          revenue: formatNumber(2)(component!.expectedPrice * coverageLevel),
          salesDate: formatDateYearMonthDay(collectionDate)
        }));
    }
  }
);

interface AllPremiumQuarters {
  type: string;
  index: number;
  rowSpan: number;
  measure: string;
  measureValues: (string | number | null | { value: number; practiceCode: number })[];
}

type MeasureType = { text: string; key: AllQuartersKeys; keyCwt: AllQuartersKeys };

const getPartialParams = createSelector(
  getShowProtectionFactorAnalysis,
  getUnitFromProps,
  getDeclaredClassPriceWeightingFactor,
  getDeclaredComponentPriceWeightingFactor,
  getQuarterSelectList,
  (
    showProtectionFactorAnalysis,
    unit,
    classweightingFactor,
    componentWeightingFactor,
    quarterList
  ) => {
    return {
      showProtectionFactorAnalysis,
      unit,
      classweightingFactor,
      componentWeightingFactor,
      quarterList
    };
  }
);

export const getAllQuartersData = createSelector(
  getAllQuartersRaw,
  getShowBasis,
  getShowSubsidy,
  getShowIndemnity,
  getPartialParams,
  (allQuarters, showBasis, showSubsidy, showIndemnity, partialParams) => {
    const {
      showProtectionFactorAnalysis,
      unit,
      classweightingFactor,
      componentWeightingFactor,
      quarterList
    } = partialParams;
    const getYearFromLabel = (quarterName: string) => {
      const [, year] = quarterName.split(',');
      return Number(year);
    };
    const getQuarterNameFromLabel = (quarterName: string) => {
      const [name] = quarterName.split(',');
      return name;
    };
    const classIII = Math.floor(classweightingFactor * 100);
    const classIV = 100 - Math.floor(classweightingFactor * 100);
    const proteinAndOtherSolids = Math.floor(componentWeightingFactor * 100);
    const nonfatSolids = 100 - Math.floor(componentWeightingFactor * 100);
    const parsedPractices = quarterList.map(q => ({
      quarter: getQuarterNameFromLabel(q.name),
      year: getYearFromLabel(q.name)
    }));

    const quarterNames = parsedPractices.map(practice => `${practice.quarter},${practice.year}`);
    const allQuartersUpdated =
      allQuarters.length > 0 && quarterNames.length > 0
        ? quarterList.map(el => {
            const quarterIndex = quarterNames.findIndex(p => {
              const cleanString = (string: string) => string.replace(/[^a-zA-Z0-9]/g, '');
              return cleanString(p) === cleanString(el.name);
            });
            if (quarterIndex !== -1) {
              return allQuarters[quarterIndex];
            } else {
              return null;
            }
          })
        : allQuarters;

    const standardMeasures: AllQuartersMeasures[] = [
      {
        text: 'Declared Butterfat Test',
        key: 'declaredButterfatTest',
        keyCwt: 'declaredButterfatTest'
      },
      { text: 'Declared Protein Test', key: 'declaredProteinTest', keyCwt: 'declaredProteinTest' },
      { text: 'Nonfat Solids Test', key: 'nonfatSolidsTest', keyCwt: 'nonfatSolidsTest' },
      { text: 'Expected Revenue', key: 'expectedRevenue', keyCwt: 'expectedRevenueCwt' },
      { text: 'Revenue Guarantee', key: 'revenueGuarantee', keyCwt: 'revenueGuaranteeCwt' },
      {
        text: 'Producer Premium Amount',
        key: 'producerPremiumAmount',
        keyCwt: 'producerPremiumAmountCwt'
      },
      {
        text: 'Expected Revenue Floor',
        key: showBasis ? 'expectedPriceFloorNet' : 'expectedPriceFloor',
        keyCwt: showBasis ? 'expectedPriceFloorNetCwt' : 'expectedPriceFloorCwt'
      },
      { text: 'Expected yield', key: 'expectedYield', keyCwt: 'expectedYield' }
    ];
    const basisMeasures: AllQuartersMeasures[] = [
      { text: 'Expected Basis', key: 'expectedBasis', keyCwt: 'expectedBasisCwt' },
      { text: 'Cost of Production', key: 'costOfProduction', keyCwt: 'costOfProductionCwt' }
    ];
    const subsidyMeasures: AllQuartersMeasures[] = [
      { text: 'Subsidy', key: 'subsidy', keyCwt: 'subsidyCwt' },
      {
        text: 'Premium Before Subsidy',
        key: 'premiumBeforeSubsidy',
        keyCwt: 'premiumBeforeSubsidyCwt'
      }
    ];
    const indemnityMeasures: AllQuartersMeasures[] = [
      {
        text: 'Probability of Positive Indemnity',
        key: 'probabilityOfPositiveIndemnity',
        keyCwt: 'probabilityOfPositiveIndemnity'
      },
      {
        text: 'Probability of Positive Net benefit',
        key: 'probabilityOfPositiveNetIndemnity',
        keyCwt: 'probabilityOfPositiveNetIndemnity'
      }
    ];
    const protectionFactorAnalysis: AllQuartersMeasures[] = [
      {
        text: 'Effective Covered Milk Production',
        key: 'milkProductionEffective',
        keyCwt: 'milkProductionEffective'
      }
    ];

    const renderingMeasures: MeasureType[] = [...standardMeasures];
    if (showBasis) {
      renderingMeasures.push(...basisMeasures);
    }
    if (showSubsidy) {
      renderingMeasures.push(...subsidyMeasures);
    }
    if (showIndemnity) {
      renderingMeasures.push(...indemnityMeasures);
    }
    if (showProtectionFactorAnalysis) {
      renderingMeasures.push(...protectionFactorAnalysis);
    }

    const rowSpan = renderingMeasures.length - 1;

    const getFormattedValue = (measure: MeasureType, quarter: AllQuarters, isComponent = true) => {
      const decimals = unit === Unit.CWT ? 2 : 0;
      const value = quarter[
        unit === Unit.CWT ? measure.keyCwt : measure.key
      ] as AllQuarterCalculatedValue;
      return formatNumber(decimals)(isComponent ? value.component : value.class, Units.DOLLAR);
    };

    const componentElementList: AllPremiumQuarters[] = renderingMeasures.map((measure, index) => {
      const componentElements: AllPremiumQuarters = {
        type: `Components (${proteinAndOtherSolids}% Protein & Other Solids, ${nonfatSolids}% Nonfat Solids)`,
        index: index,
        rowSpan,
        measure: measure.text,
        measureValues: []
      };
      allQuartersUpdated.forEach(quarter => {
        if (quarter === null) {
          if (measure.key === 'producerPremiumAmount') {
            componentElements.measureValues.push('N/A');
          } else {
            componentElements.measureValues.push(null);
          }
        } else {
          if (quarter.premiumBeforeSubsidy.component === 0) {
            if (measure.key === 'producerPremiumAmount') {
              componentElements.measureValues.push('N/A');
            } else {
              componentElements.measureValues.push(null);
            }
          } else {
            if (
              measure.key === 'declaredButterfatTest' ||
              measure.key === 'declaredProteinTest' ||
              measure.key === 'nonfatSolidsTest'
            ) {
              componentElements.measureValues.push(
                quarter[measure.key].toLocaleString(Language.DEFAULT, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })
              );
            } else if (
              measure.key === 'probabilityOfPositiveIndemnity' ||
              measure.key === 'probabilityOfPositiveNetIndemnity'
            ) {
              componentElements.measureValues.push(
                `${formatNumber(0)(quarter[measure.key].component, Units.PERCENT)}`
              );
            } else if (measure.key === 'expectedYield') {
              componentElements.measureValues.push(formatNumber(0)(quarter[measure.key].component));
            } else if (measure.key === 'milkProductionEffective') {
              componentElements.measureValues.push(formatNumber(0)(quarter[measure.key].component));
            } else if (measure.key === 'expectedRevenue') {
              componentElements.measureValues.push(getFormattedValue(measure, quarter));
            } else if (measure.key === 'revenueGuarantee') {
              componentElements.measureValues.push(getFormattedValue(measure, quarter));
            } else if (measure.key === 'expectedPriceFloorNet') {
              componentElements.measureValues.push(getFormattedValue(measure, quarter));
            } else if (measure.key === 'producerPremiumAmount') {
              componentElements.measureValues.push(getFormattedValue(measure, quarter));
            } else if (measure.key === 'expectedBasis') {
              componentElements.measureValues.push({
                value: (
                  quarter[
                    unit === Unit.CWT ? measure.keyCwt : measure.key
                  ] as AllQuarterCalculatedValue
                ).component,
                practiceCode: quarter.practiceCode
              });
            } else if (measure.key === 'costOfProduction') {
              if (unit !== Unit.CWT) {
                componentElements.measureValues.push(
                  `${
                    quarter[measure.key].component === 0
                      ? ''
                      : '$' + formatNumber(0)(quarter[measure.key].component)
                  }`
                );
              } else {
                componentElements.measureValues.push(
                  `${
                    (quarter[measure.keyCwt] as AllQuarterCalculatedValue).component === 0
                      ? ''
                      : '$' +
                        formatNumber(2)(
                          (quarter[measure.keyCwt] as AllQuarterCalculatedValue).component
                        )
                  }`
                );
              }
            } else {
              if (
                unit === Unit.CWT &&
                (measure.key === 'subsidy' || measure.key === 'premiumBeforeSubsidy')
              ) {
                componentElements.measureValues.push(
                  formatNumber(4)(
                    (quarter[measure.keyCwt] as AllQuarterCalculatedValue).component,
                    Units.DOLLAR
                  )
                );
              } else if (unit === Unit.CWT) {
                componentElements.measureValues.push(
                  formatNumber(2)(
                    (quarter[measure.keyCwt] as AllQuarterCalculatedValue).component,
                    Units.DOLLAR
                  )
                );
              } else {
                componentElements.measureValues.push(
                  formatNumber(0)(
                    (quarter[measure.key] as AllQuarterCalculatedValue).component,
                    Units.DOLLAR
                  )
                );
              }
            }
          }
        }
      });
      return componentElements;
    });

    const classElementList: Array<AllPremiumQuarters> = renderingMeasures
      .filter(
        e =>
          e.key !== 'declaredButterfatTest' &&
          e.key !== 'declaredProteinTest' &&
          e.key !== 'nonfatSolidsTest'
      )
      .map((measure, index) => {
        const classElements: AllPremiumQuarters = {
          type: `Class Blend (${classIII}% Class III, ${classIV}% Class IV)`,
          index: index,
          rowSpan: rowSpan - 3,
          measure: measure.text,
          measureValues: []
        };

        allQuartersUpdated.forEach(quarter => {
          if (quarter === null) {
            if (measure.key === 'producerPremiumAmount') {
              classElements.measureValues.push('N/A');
            } else {
              classElements.measureValues.push(null);
            }
          } else {
            if (quarter.premiumBeforeSubsidy.class === 0) {
              if (measure.key === 'producerPremiumAmount') {
                classElements.measureValues.push('N/A');
              } else {
                classElements.measureValues.push(null);
              }
            } else {
              if (
                measure.key === 'probabilityOfPositiveIndemnity' ||
                measure.key === 'probabilityOfPositiveNetIndemnity'
              ) {
                classElements.measureValues.push(
                  `${formatNumber(0)(quarter[measure.key].class, Units.PERCENT)}`
                );
              } else if (measure.key === 'milkProductionEffective') {
                classElements.measureValues.push(formatNumber(0)(quarter[measure.key].class));
              } else if (measure.key === 'expectedYield') {
                classElements.measureValues.push(formatNumber(0)(quarter[measure.key].class));
              } else if (measure.key === 'expectedRevenue') {
                classElements.measureValues.push(getFormattedValue(measure, quarter, false));
              } else if (measure.key === 'revenueGuarantee') {
                classElements.measureValues.push(getFormattedValue(measure, quarter, false));
              } else if (measure.key === 'producerPremiumAmount') {
                classElements.measureValues.push(getFormattedValue(measure, quarter, false));
              } else if (measure.key === 'expectedBasis') {
                classElements.measureValues.push({
                  value: (
                    quarter[
                      unit === Unit.CWT ? measure.keyCwt : measure.key
                    ] as AllQuarterCalculatedValue
                  ).class,
                  practiceCode: quarter.practiceCode
                });
              } else if (measure.key === 'expectedPriceFloorNet') {
                classElements.measureValues.push(getFormattedValue(measure, quarter, false));
              } else if (measure.key === 'costOfProduction') {
                if (unit !== Unit.CWT) {
                  classElements.measureValues.push(
                    `${
                      quarter[measure.key].class === 0
                        ? ''
                        : '$' + formatNumber(0)(quarter[measure.key].class)
                    }`
                  );
                } else {
                  classElements.measureValues.push(
                    `${
                      (quarter[measure.keyCwt] as AllQuarterCalculatedValue).class === 0
                        ? ''
                        : '$' +
                          formatNumber(2)(
                            (quarter[measure.keyCwt] as AllQuarterCalculatedValue).class
                          )
                    }`
                  );
                }
              } else {
                if (
                  unit === Unit.CWT &&
                  (measure.key === 'subsidy' || measure.key === 'premiumBeforeSubsidy')
                ) {
                  classElements.measureValues.push(
                    formatNumber(4)(
                      (quarter[measure.keyCwt] as AllQuarterCalculatedValue).class,
                      Units.DOLLAR
                    )
                  );
                } else if (unit === Unit.CWT) {
                  classElements.measureValues.push(
                    formatNumber(2)(
                      (quarter[measure.keyCwt] as AllQuarterCalculatedValue).class,
                      Units.DOLLAR
                    )
                  );
                } else {
                  classElements.measureValues.push(
                    formatNumber(0)(
                      (quarter[measure.key] as AllQuarterCalculatedValue).class,
                      Units.DOLLAR
                    )
                  );
                }
              }
            }
          }
        });
        return classElements;
      });

    return [...componentElementList, ...classElementList.filter(t => t)];
  }
);

export const getAllQuartersUnavailableSalesDates = createSelector(
  getAllQuartersRaw,
  getQuarterList,
  (allQuarters, quarterList) => {
    const unavailableSalesDates: string[] = [];
    allQuarters.forEach(quarter => {
      const q = quarterList.find(q => q.practiceCode === quarter.practiceCode);
      if (q) {
        if (
          quarter.premiumBeforeSubsidy.component === 0 &&
          quarter.premiumBeforeSubsidy.class === 0
        ) {
          unavailableSalesDates.push(`*Sales Not Available for ${q.quarter} (Component, Class)`);
        } else if (quarter.premiumBeforeSubsidy.component === 0) {
          unavailableSalesDates.push(`*Sales Not Available for ${q.quarter} (Component)`);
        } else if (quarter.premiumBeforeSubsidy.class === 0) {
          unavailableSalesDates.push(`*Sales Not Available for ${q.quarter} (Class)`);
        }
      }
    });
    return unavailableSalesDates;
  }
);
