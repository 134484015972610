import { getType } from 'typesafe-actions';

import { Agency } from '../../types/Agency';
import { AgencyActions, agencyActions } from './actions';

const initialState: Agency = {
  name: '',
  fullName: '',
  public: false,
  insurancePrograms: [''],
  aipAutomaticPairing: false,
  agencyCodes: undefined,
  smsService: false,
  mailSender: '',
  bindingEnabled: false,
  uiClientName: '',
  docuSignBrandId: '',
  aipAutomaticFormUpload: false
};

export const agencyReducer = (state = initialState, action: AgencyActions) => {
  switch (action.type) {
    case getType(agencyActions.setAgency):
      return { ...action.payload };
    default:
      return state;
  }
};
